import React, { useState } from 'react';
import styled from 'styled-components';

import AnimatedRoute from '../components/AnimatedRoute';
import MultiChoiceButton from '../atoms/MultiChoiceButton';

function Sex() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }
  return (
    <AnimatedRoute
      nextRoute='/birthdate'
      progressPercentage={0}
      title='Please indicate your sex'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <ButtonCotainer>
        <MultiChoiceButton onClick={() => handleNext('male')} text='Male' />
      </ButtonCotainer>

      <ButtonCotainer>
        <MultiChoiceButton onClick={() => handleNext('female')} text='Female' />
      </ButtonCotainer>
    </AnimatedRoute>
  );
}

const ButtonCotainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
`;

export default Sex;

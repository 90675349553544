import React from 'react';
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

function Topic() {
  let { dashboardId } = useParams();
  return <h3>Requested topic ID: {dashboardId}</h3>;
}

function Dashboard() {
  let match = useRouteMatch();
  return (
    <div>
      <h2>Dashboard</h2>

      <ul>
        <li>
          <Link to={`${match.url}/page1`}>page 1</Link>
        </li>
        <li>
          <Link to={`${match.url}/page2`}>page 2</Link>
        </li>
      </ul>

      <Switch>
        <Route path={`${match.path}/:dashboardId`}>
          <Topic />
        </Route>
        <Route path={match.path}>
          <h3>Please select a topic.</h3>
        </Route>
      </Switch>
    </div>
  );
}

export default Dashboard;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Dashboard from '../containers/Dashboard';
import Welcome from '../containers/Welcome';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import Name from '../containers/Name';
import Email from '../containers/Email';
import Password from '../containers/Password';
import AccountCreated from '../containers/AccountCreated';
import BookAnAppointment from '../containers/BookAnAppointment';
import Sex from '../containers/Sex';
import Birthdate from '../containers/Birthdate';
import PhoneNumber from '../containers/PhoneNumber';
import Address from '../containers/Address';
import StreetAddress from '../containers/StreetAddress';
import Skip from '../containers/Skip';

import AppointmentDescription from '../containers/AppointmentDescription';
import AppointmentBook from '../containers/AppointmentBook';
import AppointmentOverview from '../containers/AppointmentOverview';
import AppointmentConfirm from '../containers/AppointmentConfirm';

export default function Routes() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path='/dashboard'>
            <Dashboard />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/name'>
            <Name />
          </Route>
          <Route path='/email'>
            <Email />
          </Route>
          <Route path='/password'>
            <Password />
          </Route>
          <Route path='/account-created'>
            <AccountCreated />
          </Route>
          <Route path='/gender'>
            <Sex />
          </Route>
          <Route path='/birthdate'>
            <Birthdate />
          </Route>
          <Route path='/phone-number'>
            <PhoneNumber />
          </Route>
          <Route path='/street-address'>
            <StreetAddress />
          </Route>
          <Route path='/address'>
            <Address />
          </Route>
          <Route path='/book-an-appointment'>
            <BookAnAppointment />
          </Route>
          <Route path='/first-appointment-description'>
            <AppointmentDescription />
          </Route>
          <Route path='/first-appointment-book'>
            <AppointmentBook />
          </Route>
          <Route path='/first-appointment-overview'>
            <AppointmentOverview />
          </Route>
          <Route path='/first-appointment-confirm'>
            <AppointmentConfirm />
          </Route>
          <Route path='/skip'>
            <Skip />
          </Route>
          <Route path='/'>
            <Welcome />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

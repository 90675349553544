// eslint-disable-next-line import/prefer-default-export
export const palette = {
  background: '#FBF8F7',
  primary: '#F38E8E',
  secondary: '#254C52',
  darkGrey: '#EBEEF2',
  lighGrey: '#F6F7F9',
  white: '#fff',
  mediumTurqoise: '#62CBC7',
  error: '#CA3103',
  errorBackground: 'rgba(202, 49, 3, 0.03)',
  greyBorderColor: '#C3CED2',
  greyText: '#818D9B',

  darkPrimaryText: '#2F4660',
  darkSecondaryText: '#818D9B',
  melon: '#FFB59D',
  transitionScreen: '#E8E2E0',
  cadetBlue: '#3B5B80',
  primaryLightColor: '#F0F4FB',
};

import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

function AnimatedTitle({
  animationDirection,
  animationRef,
  resetAnimaions,
  children,
  progressPercentage,
  cameAfterBrowserBackButtonClick,
  cameAfterOshiHeaderBackButtonClick,
}) {
  function getAnime() {
    let anime;

    if (animationDirection === 'mount') {
      anime = {
        from: {
          opacity: 0,
          transform: cameAfterBrowserBackButtonClick
            ? 'translate3d(-100%,0,0)'
            : 'translate3d(100%,0,0)',
        },
        to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      };
    } else if (animationDirection === 'unmount') {
      anime = {
        from: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        to: {
          opacity: 0,
          transform: cameAfterOshiHeaderBackButtonClick
            ? 'translate3d(100%,0,0)'
            : 'translate3d(-100%,0,0)',
        },
      };
    }

    return anime;
  }

  const anime = getAnime();

  const titleAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    ...anime,
  });

  return (
    <TitleContainer progressPercentage={progressPercentage} style={titleAnime}>
      {children}
    </TitleContainer>
  );
}

const TitleContainer = styled(animated.div)`
  display: flex;
  justify-content: center;
  margin: 30px 0 73px;
  @media only screen and (min-width: 421px) {
    margin-bottom: 60px;
    margin-top: ${({ progressPercentage }) =>
      progressPercentage >= 0 ? '50px' : '100px'};
  }
`;

export default AnimatedTitle;

import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';
import Typography from './Typography';

function MultiChoiceButton({ text, isSelected, onClick }) {
  const animation = useSpring({
    backgroundColor: isSelected ? palette.cadetBlue : 'transparent',
  });
  return (
    <Button onClick={onClick} style={animation}>
      <Typography
        styles={{ color: isSelected ? palette.white : palette.cadetBlue }}
        type='button'
      >
        {text}
      </Typography>
    </Button>
  );
}

const Button = styled(animated.button)`
  width: 328px;
  height: 48px;
  border-radius: 4px;
  border-color: ${palette.cadetBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid ${palette.cadetBlue};
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
`;

export default MultiChoiceButton;

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Title from '../components/Title';
import Button from '../components/Button';
import OshiHeader from '../components/OshiHeader';
import { AppointmentContext } from '../utils/context';
import withTracker from '../wrappers/withTracker';

const AppointmentDescription = () => {
  const history = useHistory();
  const appointment = useContext(AppointmentContext);
  return (
    <PageContainer>
      <HeaderContainer>
        <OshiHeader
          type='back'
          action={() => history.push('/first-appointment-book')}
        />
      </HeaderContainer>
      <TitleContainer>
        <Title />
      </TitleContainer>
      <FlexContainer>
        <Paragraph>
          Can you confirm that you’re able to spend
          <Bold> 30 minutes</Bold> speaking with an Oshi Health care team member
          at this date and time?
        </Paragraph>
        <Consultation>Initial Consultation</Consultation>
        <TimeContainer>
          {appointment[0].AppointmentDate} <br />
          {appointment[0].AppointmentTime}
        </TimeContainer>
        <StyledLink to='/first-appointment-book'>Reschedule</StyledLink>
        <BottomStaticContainer>
          <Button
            onClick={() => {
              history.push('/first-appointment-confirm');
            }}
          >
            Confirm Appoinemnt
          </Button>
        </BottomStaticContainer>
      </FlexContainer>
    </PageContainer>
  );
};
const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
  background-color: #fbf8f7;
  overflow: hidden;
  @media (max-width: 768px) {
  }
`;
const TitleContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const Paragraph = styled.div`
  position: relative;
  top: 150px;
  width: 540px;
  height: 128px;
  margin: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-family: Usual, Sans-Serif;
  line-height: 153%;
  letter-spacing: 0.04em;
  white-space: pre-line;
  color: #294254;
  @media (max-width: 768px) {
    width: 343px;
    top: 93px;
  }
`;
const Bold = styled.span`
  font-weight: bold;
`;
const BottomStaticContainer = styled.div`
  position: absolute;
  top: 610px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    top: 448px;
  }
`;

const Consultation = styled.div`
  position: relative;
  top: 60px;
  font-weight: 100;
  font-family: Sans-Serif;
  font-size: 12px;
  color: #294254;
  letter-spacing: 0.06em;
  display: none;
  @media (max-width: 768px) {
    display: block;
    padding: 24px 0px;
    top: 30px;
  }
`;
const TimeContainer = styled.div`
  position: relative;
  top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 5px;
  max-width: 375px;
  height: 54px;
  width: 241px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: Usual, Sans-Serif;
  line-height: 153%;
  white-space: pre-line;
  color: #2f4660;
  @media (max-width: 768px) {
    height: 100px;
    top: 20px;
  }
`;
const StyledLink = styled(Link)`
  position: relative;
  top: 120px;
  color: #818d9b;
  font-family: Sans-Serif;
  font-size: 12px;
  letter-spacing: 0.06em;
  margin: 16px;
  text-decoration: underline;
  @media (max-width: 768px) {
    top: 30px;
  }
`;
export default connect(
  null,
  {}
)(withTracker(AppointmentDescription, 'Appointment Description Page View'));

import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

import regex from '../utils/regex';

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(regex.email, {
      message: 'Please enter a valid email address',
      excludeEmptyString: true,
    }),
});

function Email() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: () => handleNext(),
  });

  return (
    <AnimatedRoute
      nextRoute='/password'
      title='What’s your email address?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='email'
          autoFocus
          type='text'
          label='Email'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.email}
          showCheckMark={false}
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
        />
      </Form>

      <NextButtonContainer>
        <Button
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

export default Email;

import React,{useState} from 'react';
import { Provider } from 'react-redux';

import Routes from './routes';
import store from './store';
import {AppointmentContext} from './utils/context'

function App() {
  const [appointment, setAppointment] = useState({
    Appointment: false,
    AppointmentTime: '',
    AppointmentDate: '',
  });
  return (
    <Provider store={store}>
      <AppointmentContext.Provider value = {[appointment, setAppointment]}>
       <Routes />
      </AppointmentContext.Provider>
    </Provider>
  );
}

export default App;


import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

function AnimatedContent({
  animationDirection,
  animationRef,
  resetAnimaions,
  children,
  handleAnimationFinish,
  style,
}) {
  const contentAnime = useSpring({
    reset: resetAnimaions,
    ref: animationRef,
    onRest: handleAnimationFinish,
    from: { opacity: animationDirection === 'mount' ? 0 : 1 },
    to: { opacity: animationDirection === 'mount' ? 1 : 0 },
  });

  return (
    <Container style={{ ...contentAnime, ...style }}>{children}</Container>
  );
}

const Container = styled(animated.div)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export default AnimatedContent;

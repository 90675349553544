import { segmentTypes } from '../constants';

const segment = (state = {}, action) => {
  switch (action.type) {
    case segmentTypes.PAGE_VIEW_EVENT:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_ACCEPTED:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_EXPANDED:
      return state;
    case segmentTypes.PRIVACY_CONTAINER_SEEN:
      return state;
    case segmentTypes.PRIVACY_PAGE_EXIT:
      return state;
    case segmentTypes.VALUE_PROP_EVENT:
      return state;
    case segmentTypes.LANDING_GET_STARTED:
      return state;
    case segmentTypes.LANDING_LOGIN:
      return state;
    default:
      return state;
  }
};

export default segment;

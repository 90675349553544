/* eslint-disable default-case */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import InputMask from 'react-input-mask';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import {
  ShowPasswordEye,
  DontShowPasswordEye,
  CheckMark,
} from '../assets/svgs';

const AnimatedInput = animated(InputMask);

const inputStates = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ERROR: 'ERROR',
  DISABLED: 'DISABLED',
};

function OshiInput(props) {
  const {
    type,
    label,
    error,
    disabled,
    autofocus,
    isValid,
    mask,
    forwardRef,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const getInputStatus = () => {
    if (disabled) {
      return inputStates.DISABLED;
    }

    if (autofocus) {
      return inputStates.ACTIVE;
    }

    return inputStates.INACTIVE;
  };

  const [inputStatus, setInputStatus] = useState(getInputStatus());

  function handleOnBlur() {
    setInputStatus(inputStates.INACTIVE);
  }

  function handleOnFocus() {
    setInputStatus(inputStates.ACTIVE);
  }

  function getInputStyles() {
    switch (inputStatus) {
      case inputStates.ACTIVE:
        return {
          backgroundColor: 'transparent',
          borderBottom: '2px solid',
          borderBottomColor: palette.mediumTurqoise,
          caretColor: palette.darkPrimaryText,
          color: palette.darkPrimaryText,
        };
      case inputStates.DISABLED:
        return {
          backgroundColor: 'transparent',
          borderBottom: `1px solid`,
          borderBottomColor: palette.greyBorderColor,
          caretColor: palette.greyText,
          opacity: 0.7,
        };
      case inputStates.ERROR:
        return {
          backgroundColor: palette.errorBackground,
          caretColor: palette.errorBackground,
          borderBottom: '2px solid',
          borderBottomColor: palette.error,
        };
    }

    return {
      backgroundColor: 'transparent',
      borderBottom: '2px solid',
      borderBottomColor: palette.greyBorderColor,
      caretColor: palette.greyText,
    };
  }

  function getIcon() {
    switch (type) {
      case 'password':
        return showPassword ? (
          <ShowPasswordEye color={palette.greyBorderColor} />
        ) : (
          <DontShowPasswordEye color={palette.greyBorderColor} />
        );
    }

    return null;
  }

  function handleIconClick() {}

  const inputStyles = getInputStyles();
  const inputAnime = useSpring(inputStyles);
  const titleAnime = useSpring({ color: inputStyles.borderBottomColor });

  return (
    <Container>
      <Title style={{ ...titleAnime }}>{label}</Title>

      <InputContainer>
        <Input
          {...props}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          ref={forwardRef}
          mask={mask}
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          style={{ ...inputAnime }}
        />

        <IconContainer>
          <ChildrenContainer
            onMouseDown={(e) => e.preventDefault()}
            onClick={() =>
              type === 'password'
                ? setShowPassword(!showPassword)
                : handleIconClick()
            }
          >
            {getIcon()}
          </ChildrenContainer>
          {isValid && <CheckMark color={palette.mediumTurqoise} />}
        </IconContainer>
      </InputContainer>

      {error && (
        <Typography styles={{ color: palette.error }}>{error}</Typography>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
`;

const ChildrenContainer = styled.div`
  margin-right: 10px;
`;

const Input = styled(AnimatedInput)`
  display: flex;
  border: none;
  height: 41px;
  margin-bottom: 8px;
  padding-left: 8px;
  font-family: 'Usual';
  font-weight: 300;
`;

const Title = styled(animated.p)`
  margin-bottom: 8px;
  font-size: 12px;
  font-family: 'Usual';
  line-height: 18px;
`;

export default OshiInput;

import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';
import { ChevronDownIcon, CheckMark } from '../assets/svgs';
import Typography from '../atoms/Typography';
import CheckCircle from '../atoms/CheckCircle';

const collapsedHeight = '60px';

function ExpandableComponent({
  children,
  handleCheckOnClick,
  title,
  componentKey,
  isChecked,
  error,
  isExpanded,
  onClick,
  onScroll,
}) {
  const heightProps = useSpring({
    config: { mass: 1, tension: 180, friction: 20 },
    height: isExpanded ? '280px' : collapsedHeight,
  });

  const backgroundAnime = useSpring({
    backgroundColor: error
      ? palette.errorBackground
      : isExpanded
      ? palette.lighGrey
      : palette.darkGrey,
    borderColor: error ? palette.error : palette.greyBorderColor,
  });

  const arrowAnime = useSpring({
    transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
  });

  return (
    <Container style={{ ...heightProps, ...backgroundAnime }}>
      <ClickableContainer onClick={() => onClick(componentKey)}>
        <ChevronContainer style={arrowAnime}>
          <ChevronDownIcon color={error && palette.error} />
        </ChevronContainer>

        <TitleContainer>
          <Typography styles={{ color: error ? palette.error : null }}>
            {title}
          </Typography>
        </TitleContainer>

        <CheckCircleContainer>
          <CheckCircle
            {...{ componentKey }}
            onClick={handleCheckOnClick}
            borderColor={error ? palette.error : palette.greyBorderColor}
            nonFilledColor={palette.white}
            isFilled={isChecked}
          >
            <CheckMark />
          </CheckCircle>
        </CheckCircleContainer>
      </ClickableContainer>

      {isExpanded && (
        <ContentContainer onScroll={(e) => onScroll(e, componentKey)}>
          {children}
        </ContentContainer>
      )}
    </Container>
  );
}

const Container = styled(animated.div)`
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  position: relative;
  margin: 5px 0;
`;

const ClickableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${collapsedHeight};
  width: 100%;
  display: flex;
  align-items: center;
`;

const ChevronContainer = styled(animated.div)`
  padding: 0 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const CheckCircleContainer = styled.div`
  padding: 0 20px;
`;

const ContentContainer = styled.div`
  margin-top: ${collapsedHeight};
  flex: 1;
  overflow-y: scroll;
  padding: 0 15px 15px;
`;

export default ExpandableComponent;

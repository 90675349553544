/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

function Address() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const { location } = useHistory();
  let googleMapsSuggestedAddress = null;

  location && location.state
    ? (googleMapsSuggestedAddress = location.state.googleMapsSuggestedAddress)
    : null;

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      streetAddress: googleMapsSuggestedAddress
        ? `${googleMapsSuggestedAddress[0].short_name}, ${googleMapsSuggestedAddress[1].short_name}`
        : '',
      apartment: '',
      city: googleMapsSuggestedAddress
        ? googleMapsSuggestedAddress[3].short_name
        : '',
      state: googleMapsSuggestedAddress
        ? googleMapsSuggestedAddress[5].short_name
        : '',
      zip: googleMapsSuggestedAddress
        ? googleMapsSuggestedAddress[7].short_name
        : '',
    },
    onSubmit: () => handleNext(),
  });

  return (
    <AnimatedRoute
      nextRoute='/book-an-appointment'
      progressPercentage={100}
      title='Is this is correct?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='streetAddress'
          type='text'
          label='Address'
          autoComplete='off'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.streetAddress}
        />

        <OshiInput
          id='apartment'
          type='text'
          label='Apartment / Suite # (optional)'
          autoComplete='off'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.apartment}
        />

        <CityStateContainer>
          <div style={{ width: '45%', marginRight: '10%' }}>
            <OshiInput
              id='city'
              type='text'
              label='City'
              autoComplete='off'
              isValid={formik.dirty && Object.keys(formik.errors).length === 0}
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </div>
          <div style={{ width: '45%' }}>
            <OshiInput
              id='state'
              type='text'
              label='State'
              autoComplete='off'
              isValid={formik.dirty && Object.keys(formik.errors).length === 0}
              onChange={formik.handleChange}
              value={formik.values.state}
            />
          </div>
        </CityStateContainer>

        <CityStateContainer>
          <div style={{ width: '45%', marginRight: '10%' }}>
            <OshiInput
              id='zip'
              type='text'
              label='Zip'
              autoComplete='off'
              isValid={formik.dirty && Object.keys(formik.errors).length === 0}
              onChange={formik.handleChange}
              value={formik.values.zip}
            />
          </div>
        </CityStateContainer>
      </Form>

      <NextButtonContainer>
        <Button onClick={formik.submitForm}>Continue</Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  margin-bottom: 20px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

const CityStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export default Address;

import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import closeIcon from '../img/closeIcon.png';

const ModalInput = (props) => {
  const [note, setNote] = useState();
  const placeholder =
    'Have something to tell your Oshi Health provider to know before your appt?';
  const { onClose, onSubmit } = props;
  return (
    <PageContainer>
      <Overlay />
      <FlexContainer>
        <ContentContainer>
          <CloseContainere>
            <Icon src={closeIcon} onClick={() => onClose()} />
          </CloseContainere>
          <InputForm
            type='textarea'
            placeholder={placeholder}
            autoFocus
            onChange={(event) => setNote(event.target.value)}
          />
          <ButtonContainer>
            <Button disabled={false} onClick={() => onSubmit(note)}>
              Send Note
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  @media (max-width: 768px) {
    height: 100%;
    z-index: 3;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #3b5b80;
  opacity: 0.5;
  z-index: 0;
`;
const FlexContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 10;
`;
const ContentContainer = styled.div`
  position: relative;
  top: 70px;
  width: 624px;
  height: 357px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    top: 0px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
  }
`;
const InputForm = styled.textarea`
  width: 95%;
  height: 200px;
  backgroud-color: lightgray;
  resize: none;
  font-family: Usual;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 168%;
  tex-alignl: center;
  margin-top: 30px;
  border: none;
  position: relative;
  bottom: 15px;
  ::placeholder {
    color: #a8b7c8;
  }
  @media (max-width: 768px) {
    position: relative;
    top: 0px;
    font-size: 16px;
  }
`;
const CloseContainere = styled.div`
  width: 95%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  backgroud-color: gray;
  @media (max-width: 768px) {
    position: relative;
    top: 10px;
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0px 0px;
    height: 30px;
  }
`;
const ButtonContainer = styled.div`
  width: auto;
  height: auto;
  padding: 16px 24px;
  backgroud-color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export default ModalInput;

import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../components/Button';
import { AppointmentContext } from '../utils/context';
import CoachProfilePhoto from '../img/coachProfile.png';
import ArrowNext from '../img/arrowNext.png';
import OshiHeader from '../components/OshiHeader';
import Title from '../components/Title';
import ModalInput from '../components/ModalInput';
import withTracker from '../wrappers/withTracker';

const AppointmentConfirm = () => {
  const history = useHistory();
  const appointment = useContext(AppointmentContext);
  const [, setNote] = useState('');
  const [app] = useState('Monday, June 22, 2020');
  const [time] = useState('04:30 PM');
  const [modal, setModal] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
  const onSubmitNote = (value) => {
    setNote(value);
    setModal(false);
  };

  return (
    <PageContainer>
      {modal ? (
        <ModalInput onSubmit={onSubmitNote} onClose={() => setModal(false)} />
      ) : (
        ''
      )}
      <HeaderContainer>
        <OshiHeader
          type='back'
          action={() => history.push('/first-appointment-book')}
        />
      </HeaderContainer>
      <TitleContainer>
        <Title />
      </TitleContainer>
      <FlexContainer>
        <ContentContainer>
          <Confirm>Your appointment is confirmed!</Confirm>
          <TopContainer>
            <AppointmentInfo>
              <Intro>
                You have an appointment with <Bold>Tom Burdel</Bold> on&nbsp;
                <MobileTime>
                  {appointment[0] ? (
                    <div>
                      <Bold>{appointment[0].AppointmentDate}</Bold> at&nbsp;
                      <Bold>{appointment[0].AppointmentTime}</Bold>
                    </div>
                  ) : (
                    ''
                  )}
                </MobileTime>
              </Intro>
              <Time>
                <Bold>{app}</Bold> at <Bold>{time}</Bold>
              </Time>
            </AppointmentInfo>
            <ActionsContainer>
              <Action onClick={() => setModal(true)}>Note to Provider</Action>
            </ActionsContainer>
          </TopContainer>
          <AboutWrapper>
            <AboutContainer>
              <AboutTitle>About your appointments</AboutTitle>
              <AboutDescription>
                This is a 30 minutes consultation so we can properly assess your
                health concerns and introduce you to Oshi Health’s services.
              </AboutDescription>
            </AboutContainer>
            <AboutContainer>
              <AboutTitle>About the Oshi Health Provider</AboutTitle>
              <AboutDescription>
                <ProfileTitleCard>
                  <LeftContainer>
                    <ProfilePhoto src={CoachProfilePhoto} />
                    <ProfileInfo>
                      <Name>Tom Burdel</Name>
                      <Profession>Health Coach</Profession>
                    </ProfileInfo>
                  </LeftContainer>
                  <Icon src={ArrowNext} />
                </ProfileTitleCard>
              </AboutDescription>
            </AboutContainer>
          </AboutWrapper>
        </ContentContainer>
        <BottomStaticContainer>
          <Button
            onClick={() => {
              history.push('/first-appointment-confirm');
            }}
          >
            Go to the Next Steps
          </Button>
        </BottomStaticContainer>
      </FlexContainer>
    </PageContainer>
  );
};
const TitleContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
  background-color: #fbf8f7;
  overflow: hidden;
  font-family: Usual, Sans-Serif;
  color: #2f4660;
  @media (max-width: 768px) {
  }
`;
const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const ContentContainer = styled.div`
  position: relative;
  top: 100px;
  display: flex;
  width: 522px;
  height: 460px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    width: 343px;
    height: 500px;
    top: 60px;
  }
`;
const TopContainer = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Confirm = styled.div`
  position: relative;
  bottom: 10px;
  width: 100%;
  height: 10px;
  text-align: center;
  letter-spacing: 0.06em;
  font-size: 12px;
  line-height: 147%;
  font-weight: 100;
  color: #818d9b;
`;
const AppointmentInfo = styled.div`
  width: 528px;
  height: 55px;
  position: relative;
  top: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-family: Usual;
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  letter-spacing: 0.03em;
  line-height: 134.7%;
  @media (max-width: 768px) {
    top: 0px;
    width: 327px;
    height: 110px;
    display: block;
  }
`;
const Intro = styled.div``;
const Time = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileTime = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;
const Bold = styled.span`
  font-weight: 600;
`;
const ActionsContainer = styled.div`
  width: 330px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 230px;
    height: 40px;
  }
`;
const Action = styled.div`
  border-bottom: 1px solid #818d9b;
  font-size: 12px;
  letter-spacing: 0.06em;
  cursor: pointer;
  color: #818d9b;
`;
const AboutWrapper = styled.div`
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    height: 260px;
  }
`;
const AboutContainer = styled.div`
  height: 115px;
  width: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-between;
  @media (max-width: 768px) {
    width: 343px;
    height: 115px;
    justify-content: space-between;
  }
`;
const AboutTitle = styled.div`
  max-width: 375px;
  text-align: left;
  font-size: 18px;
  font-family: Usual, Sans-Serif;
  font-weight: 500;
  line-height: 153%;
  white-space: pre-line;
  color: #294254;
`;
const AboutDescription = styled.div`
  max-width: 528px;
  text-align: left;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Usual;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 168%;
  white-space: pre-line;
  color: #818d9b;
  @media (max-width: 768px) {
    max-width: 375px;
    height: 70px;
  }
`;
const ProfileTitleCard = styled.div`
  width: 528px;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  boder: 1px solid gray;
  background-color: #fff;
  @media (max-width: 768px) {
    width: 343px;
    height: 66px;
  }
`;
const LeftContainer = styled.div`
  width: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ProfilePhoto = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 10px;
`;
const Icon = styled.img`
  width: 7px;
  height: 12px;
  margin-right: 15px;
`;
const ProfileInfo = styled.div`
  width: 100px;
  height: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    right: 70px;
  }
`;
const Name = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2f4660;
`;
const Profession = styled.div`
	font-size;12px;
	font-weight:200;
	color:#818D9B;
`;
const BottomStaticContainer = styled.div`
  position: absolute;
  top: 610px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 0;
  @media (max-width: 768px) {
    top: 570px;
  }
`;
export default connect(
  null,
  {}
)(withTracker(AppointmentConfirm, 'Appointment Confirm Page View'));

import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

import regex from '../utils/regex';
import getRepeatItemArray from '../utils/getRepeatItemArray';

const nameValidationSchema = Yup.object({
  firstName: Yup.string().required('Enter a first name'),
  lastName: Yup.string().required('Enter a last name'),
});

function Name() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema: nameValidationSchema,
    onSubmit: () => handleNext(),
  });

  return (
    <AnimatedRoute
      nextRoute='/email'
      title='What’s your name?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='firstName'
          autoFocus
          type='text'
          label='First Name'
          autoComplete='off'
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.firstName}
          mask={getRepeatItemArray(regex.name, 50)}
          showCheckMark={false}
        />

        <OshiInput
          id='lastName'
          type='text'
          label='Last Name'
          autoComplete='off'
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.lastName}
          mask={getRepeatItemArray(regex.name, 50)}
          showCheckMark={false}
        />
      </Form>

      <NextButtonContainer>
        <Button
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

export default Name;

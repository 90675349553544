import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Title from '../components/Title';
import Button from '../components/Button';
import OshiHeader from '../components/OshiHeader';
import ModalConfirm from '../components/ModalConfirm';
import withTracker from '../wrappers/withTracker';

const AppointmentDescription = () => {
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const modalFeedback = (value) => {
    value === 'nevermind' ? setModal(false) : history.push('/skip');
  };
  return (
    <PageContainer>
      {modal ? (
        <ModalConfirm
          modalFeedback={modalFeedback}
          onClose={() => setModal(false)}
        />
      ) : (
        ''
      )}

      <HeaderContainer>
        <OshiHeader />
      </HeaderContainer>
      <TitleContainer>
        <Title />
      </TitleContainer>
      <FlexContainer>
        <PageTitle>For your initial visit</PageTitle>
        <Paragraph>
          We’d like to dedicate <Bold>30 minutes</Bold> to your initial
          consultation so we can properly assess your health concerns and
          introduce you to Oshi Health’s services
        </Paragraph>
        <BottomStaticContainer>
          <Button
            onClick={() => {
              history.push('/first-appointment-book');
            }}
          >
            Get Started Now
          </Button>
          <StyledLink onClick={() => setModal(true)}>Skip for now</StyledLink>
        </BottomStaticContainer>
      </FlexContainer>
    </PageContainer>
  );
};
const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
  background-color: #fbf8f7;
  overflow: hidden;
  @media (max-width: 768px) {
  }
`;
const TitleContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const PageTitle = styled.div`
  position: relative;
  top: 50px;
  width: 375px;
  height: 75px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Usual, Sans-Serif;
  line-height: 150%;
  white-space: pre-line;
  color: #294254;
`;
const Paragraph = styled.div`
  position: relative;
  top: 150px;
  width: 560px;
  height: 104px;
  margin: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-family: Usual, Sans-Serif;
  line-height: 153%;
  letter-spacing: 0.04em;
  white-space: pre-line;
  color: #294254;
  @media (max-width: 768px) {
    display: block;
    position: relative;
    top: 93px;
    width: 365px;
    height: 128px;
    margin: 36px;
  }
`;
const Bold = styled.span`
  font-weight: bold;
`;
const BottomStaticContainer = styled.div`
  position: absolute;
  top: 610px;
  width: 100vw;
  height: 89px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    top: 448px;
  }
`;

const StyledLink = styled(Link)`
  color: #818d9b;
  font-family: Sans-Serif;
  letter-spacing: 0.06em;
  margin: 16px;
  text-decoration: underline;
`;

export default connect(
  null,
  {}
)(withTracker(AppointmentDescription, 'Appointment Description Page View'));

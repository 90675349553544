import React from 'react';
import Typography from '../atoms/Typography';

const Button = ({ bgcolor, children, color, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: bgcolor ? bgcolor : '#6AD3D1',
        color: color ? color : '#FFFFFF',
        borderRadius: '100px',
        width: '192px',
        height: '41px',
        border: 'none',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Typography type={'button'}>{children}</Typography>
    </button>
  );
};

export default Button;

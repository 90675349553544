import React from 'react';
import styled from 'styled-components';
import { CheckMark } from '../assets/svgs';
import Typography from './Typography';
import { palette } from '../theme/palette';

function ErrorMessage({ error, isValid }) {
  const color = isValid ? palette.darkPrimaryText : palette.greyText;
  return (
    <Container>
      <CheckContainer>
        <CheckMark color={isValid ? palette.darkPrimaryText : 'transparent'} />
      </CheckContainer>

      <ErrorContainer>
        <Typography type='subcopy' styles={{ color }}>
          {error}
        </Typography>
      </ErrorContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CheckContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ErrorContainer = styled.div`
  display: flex;
`;

export default ErrorMessage;

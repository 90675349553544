/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { palette } from '../theme/palette';
import ExpandableComponent from '../components/ExpandableComponent';
import Typography from '../atoms/Typography';
import CheckCircle from '../atoms/CheckCircle';
import { ArrowRight } from '../assets/svgs';
import withTracker from '../wrappers/withTracker';
import { createTrackEvent } from '../actions/segment';
import { segmentTypes } from '../constants';
import OshiInput from '../components/OshiInput';

const dummyText = `Where does it come from?
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`;

function PrivacyPolicy({ createTrackEvent }) {
  const [checkedPolicies, setCheckedPolicies] = useState([
    {
      title: 'Membership Terms of Service',
      text: dummyText,
      key: 'TOS',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      title: 'Membership Privacy Policy',
      text: dummyText,
      key: 'MPP',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      title: 'HIPAA Release of Information',
      text: dummyText,
      key: 'HIPAA',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
    {
      acknowledgement: true, // used to display "And I acknowledge the receipt of"
      title: 'Notice of Privacy Practice',
      text: dummyText,
      key: 'NPP',
      isChecked: false,
      error: false,
      isExpanded: false,
    },
  ]);

  const areAllPoliciesChecked =
    checkedPolicies.filter((policy) => !policy.isChecked).length <= 0;
  const isThereAnError =
    checkedPolicies.filter((policy) => policy.error).length > 0;

  function updateCheckedPolicies(key) {
    const checkedPoliciesClone = [...checkedPolicies];

    checkedPoliciesClone.forEach((policy) => {
      if (policy.key === key) {
        !policy.isChecked &&
          createTrackEvent(
            segmentTypes.PRIVACY_CONTAINER_ACCEPTED,
            `Privacy ${policy.title} Accepted`
          );
        policy.isChecked = !policy.isChecked;

        if (policy.error) {
          policy.error = false;
        }
      }
    });

    setCheckedPolicies(checkedPoliciesClone);
  }

  function handleExpandClick(key) {
    const checkedPoliciesClone = [...checkedPolicies];

    checkedPoliciesClone.forEach((policy) => {
      policy.isExpanded = policy.key === key && !policy.isExpanded;

      if (policy.error && policy.key === key) {
        policy.error = false;
      }

      if (policy.key === key && policy.isExpanded) {
        // send segment even if opened
        createTrackEvent(
          segmentTypes.PRIVACY_CONTAINER_EXPANDED,
          `Privacy ${policy.title} Expanded`
        );
      }
    });

    setCheckedPolicies(checkedPoliciesClone);
  }

  function handleExpandableComponentScroll(event, key) {
    const checkedPoliciesClone = [...checkedPolicies];
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (!bottom) return;

    checkedPoliciesClone.forEach((policy) => {
      if (policy.key === key) {
        createTrackEvent(
          segmentTypes.PRIVACY_CONTAINER_SEEN,
          `Privacy ${policy.title} Seen`
        );
      }
    });
  }

  function handleNext() {
    if (!areAllPoliciesChecked) {
      const checkedPoliciesClone = [...checkedPolicies];

      checkedPoliciesClone.forEach((policy) => {
        if (!policy.isChecked) {
          policy.error = true;
        }
      });

      setCheckedPolicies(checkedPoliciesClone);
    }

    createTrackEvent(segmentTypes.PRIVACY_PAGE_EXIT, `Privacy Page Exit`);
  }

  return (
    <Container>
      <PaddedContainer>
        <TextContainer style={{ padding: '25px' }}>
          <Typography type='paragraph'>Legal Policy</Typography>
        </TextContainer>

        <TextContainer style={{ marginBottom: '21px' }}>
          <Typography>Oshi Health's Privacy Promise</Typography>
        </TextContainer>

        <TextContainerNotCentered style={{ marginBottom: '17px' }}>
          <Typography styles={{ marginBottom: '8px' }} type='subcopy'>
            What kinds of information do we collect?
          </Typography>
          <Typography type='lightSmall'>
            Health and contact information provided by you as well as how and
            when you use our app.
          </Typography>
        </TextContainerNotCentered>

        <TextContainerNotCentered style={{ marginBottom: '17px' }}>
          <Typography styles={{ marginBottom: '8px' }} type='subcopy'>
            How do we use this information?
          </Typography>
          <Typography type='lightSmall'>
            Your health information is only visible to your care team and is
            used solely to manage your care.
          </Typography>
        </TextContainerNotCentered>

        <TextContainer>
          <Typography type='boldSmall'>
            I am at least 18 years of age and I have read and accept the
            following
          </Typography>
        </TextContainer>

        <ExpandableComponentContainer>
          {checkedPolicies.map((policy, index) => {
            return (
              <Fragment key={policy.key}>
                {policy.acknowledgement && (
                  <AcknowledgementContainer>
                    <Typography type='boldSmall'>
                      And I acknowledge the receipt of
                    </Typography>
                  </AcknowledgementContainer>
                )}

                <ExpandableComponent
                  error={policy.error}
                  handleCheckOnClick={updateCheckedPolicies}
                  isExpanded={policy.isExpanded}
                  onClick={handleExpandClick}
                  onScroll={handleExpandableComponentScroll}
                  title={policy.title}
                  componentKey={policy.key}
                  isChecked={policy.isChecked}
                >
                  <Typography type='subcopy'>{policy.text}</Typography>
                </ExpandableComponent>
              </Fragment>
            );
          })}

          {isThereAnError && (
            <ErrorContainer>
              <Typography type='subcopy'>
                You must accept the{' '}
                <Typography type='boldSmall'>Privacy Policy</Typography>,{' '}
                <Typography type='boldSmall'>Terms of Service</Typography>,{' '}
                <Typography type='boldSmall'>Privacy Practices</Typography> and{' '}
                <Typography type='boldSmall'>HIPAA Authorization</Typography> in
                order to continue.
              </Typography>
            </ErrorContainer>
          )}
          <OshiInput />
        </ExpandableComponentContainer>

        <NextButtonContainer>
          <CheckCircle
            onClick={handleNext}
            isFilled={areAllPoliciesChecked}
            nonFilledColor={palette.greyBorderColor}
            borderColor='rgba(0, 0, 0, 0)'
            styles={{
              height: 50,
              width: 50,
              borderRadius: 30,
              marginRight: 10,
            }}
          >
            <ArrowRight />
          </CheckCircle>
        </NextButtonContainer>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.darkGrey};

  justify-content: center;
  position: relative;
`;

const PaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  background: ${palette.darkGrey};
  position: relative;
  max-width: 500px;
`;

const ExpandableComponentContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 25px;
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
`;

const TextContainerNotCentered = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 25%;
`;

const AcknowledgementContainer = styled.div`
  display: flex;
  padding: 10px 10px 5px;
`;

const ErrorContainer = styled.div`
  display: flex;
  padding: 10px 10px 5px;
  color: ${palette.error};
`;

export default connect(null, { createTrackEvent })(
  withTracker(PrivacyPolicy, 'Privacy Page View')
);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { pageViewEvent } from '../actions/segment';

const withTracker = (WrappedComponent, pageName) => {
  const HOC = (props) => {
    useEffect(() => {
      props.pageViewEvent(pageName);
    });

    return <WrappedComponent {...props} />;
  };

  return connect(null, {
    pageViewEvent,
  })(HOC);
};

export default withTracker;

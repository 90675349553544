import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { palette } from '../theme/palette';

function OshiProgressBar({ progressPercentage }) {
  const history = useHistory();
  const goingBack = history.action === 'POP';
  const [fromWidth] = useState(`${getProgressFromWidth(progressPercentage)}%`);
  const [toWidth] = useState(`${progressPercentage}%`);

  function getProgressFromWidth(percentage) {
    if (goingBack) {
      return percentage + 25;
    }
    return percentage - 25;
  }

  const progressAnime = useSpring({
    from: {
      width: fromWidth,
    },
    to: { width: toWidth },
  });

  return (
    <ProgressContainer>
      <Progress style={progressAnime} progressPercentage={progressPercentage} />
    </ProgressContainer>
  );
}

const ProgressContainer = styled.div`
  width: 80%;
  background-color: ${palette.transitionScreen};
  border-radius: 90px;
  margin-top: 27px;
  @media only screen and (min-width: 421px) {
    margin-top: 60px;
  }
`;

const Progress = styled(animated.div)`
  width: ${(props) => props.progressPercentage || '0%'};
  height: 6px;
  background-color: ${palette.mediumTurqoise};
  border-radius: 90px;
`;

export default React.memo(OshiProgressBar);

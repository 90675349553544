import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

import InputErrorMessage from '../atoms/InputErrorMessage';

import regex from '../utils/regex';

function Password() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  function passwordValidate({ password }) {
    const errors = {};

    if (password.length < 8) {
      errors.length = '8 or more characters';
    }

    if (!regex.atLeastOneUpperCase.test(password)) {
      errors.upperCase = 'At least one uppercase letter (A-Z)';
    }

    if (!regex.atLeastOneLetter.test(password)) {
      errors.lowerCase = 'At least one lowercase letter (a-z)';
    }

    if (!regex.atLeastOneDigit.test(password)) {
      errors.digit = 'At least one number (0-9)';
    }

    return errors;
  }

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      phoneNumber: 'password',
    },
    validate: passwordValidate,
    onSubmit: () => handleNext(),
  });

  return (
    <AnimatedRoute
      nextRoute='/account-created'
      title='And choose a password'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='password'
          autoFocus
          type='password'
          label='Password'
          autoComplete='off'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          onChange={formik.handleChange}
          value={formik.values.birthday}
        />

        <ErrorsContainer>
          <InputErrorMessage
            isValid={formik.dirty && !formik.errors.length}
            error='Minimum of 8 characters'
          />
          <InputErrorMessage
            isValid={formik.dirty && !formik.errors.upperCase}
            error='At least one uppercase letter (A-Z)'
          />
          <InputErrorMessage
            isValid={formik.dirty && !formik.errors.lowerCase}
            error='At least one lowercase letter (a-z)'
          />
          <InputErrorMessage
            isValid={formik.dirty && !formik.errors.digit}
            error='At least one number (0-9)'
          />
        </ErrorsContainer>
      </Form>

      <NextButtonContainer>
        <Button
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Password;

/* eslint-disable import/prefer-default-export */
export const segmentTypes = {
  PAGE_VIEW_EVENT: 'PAGE_VIEW_EVENT',
  PRIVACY_CONTAINER_EXPANDED: 'PRIVACY_CONTAINER_EXPANDED',
  PRIVACY_CONTAINER_SEEN: 'PRIVACY_CONTAINER_SEEN',
  PRIVACY_CONTAINER_ACCEPTED: 'PRIVACY_CONTAINER_ACCEPTED',
  PRIVACY_PAGE_EXIT: 'PRIVACY_PAGE_EXIT',
  VALUE_PROP_EVENT: 'VALUE_PROP_EVENT',
  LANDING_GET_STARTED: 'LANDING_GET_STARTED',
  LANDING_LOGIN: 'LANDING_LOGIN',
};

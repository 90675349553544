import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import closeIcon from '../img/closeIcon.png';

const ModalConfirm = (props) => {
  const { onClose, modalFeedback } = props;
  return (
    <PageContainer>
      <Overlay />
      <FlexContainer>
        <ContentContainer>
          <CloseContainere>
            <Icon src={closeIcon} onClick={() => onClose()} />
          </CloseContainere>
          <DescriptionContainer>
            <Title>Are you sure you want to skip?</Title>
            <Description>
              By skipping, all progress made in this session will be lost.{' '}
            </Description>
          </DescriptionContainer>
          <ActionContainer>
            <ButtonContainer>
              <Button
                disabled={false}
                onClick={() => modalFeedback('nevermind')}
              >
                Nevermind
              </Button>
            </ButtonContainer>
            <StyledLink onClick={() => modalFeedback('skip')}>
              Continue Skipping
            </StyledLink>
          </ActionContainer>
        </ContentContainer>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  @media (max-width: 768px) {
    height: 100%;
    z-index: 3;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #3b5b80;
  opacity: 0.5;
  z-index: 0;
`;
const FlexContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 10;
`;
const ContentContainer = styled.div`
  position: relative;
  top: 135px;
  width: 382px;
  height: 286px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #fbf8f7;
  box-shadow: 0px 4px 10px #afb6be;
  @media (max-width: 768px) {
    position: relative;
    top: 0px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
  }
`;
const CloseContainere = styled.div`
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  backgroud-color: gray;
  @media (max-width: 768px) {
    position: relative;
    top: 10px;
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0px 0px;
    height: 30px;
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const DescriptionContainer = styled.div`
  width: auto;
  height: 95px;
  position: relative;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    top: 100px;
  }
`;
const Title = styled.div`
  font-family: Usual;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 147.7%;
  color: #2f4660;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
`;
const Description = styled.div`
  width: 334px;
  height: 48px;
  font-family: Usual;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 168%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #2f4660;
`;
const ActionContainer = styled.div`
  width: 192px;
  height: 70px;
  position: relative;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    top: 100px;
  }
`;
const ButtonContainer = styled.div`
  width: auto;
  height: auto;
  backgroud-color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    position: relative;
    top: 100px;
  }
`;
const StyledLink = styled.div`
  font-family: Usual;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  color: #818d9b;
  cursor: pointer;
  @media (max-width: 768px) {
    position: relative;
    top: 100px;
  }
`;
export default ModalConfirm;

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import Button from '../components/Button';
import logo from '../img/logo.png';
import arrowNext from '../img/arrowNext.png';
import arrowPrevious from '../img/arrowPrevious.png';
import ProgressIndicator from '../components/ProgressIndicator';
import withTracker from '../wrappers/withTracker';
import {
  NextValueProp,
  LandingGetStarted,
  LandingLogin,
} from '../actions/segment';

function Welcome(props) {
  const [index, set] = useState(0);
  const [introduction, setIntroduction] = useState(true);
  const history = useHistory();
  const [direction, setDirection] = useState('-1');
  const { NextValueProp, LandingGetStarted, LandingLogin } = props;

  const keys = [1, 2, 3, 4];
  const Copy = [
    'Your holistic GI \n care team',
    'Personalized \n whole-health plans',
    'Transparent costs,\n no surprises',
    'Proactive, easy and \n seamless care',
  ];
  const SubCopy = [
    'Address all of your gut-health needs with \n your GI care team, including expert \n health coaches.',
    'Work with your health coach to set and \n achieve personal health \n and lifestyle goals.',
    'We’ll help you achieve your health goals \n without breaking your budget.',
    'We’ll coordinate your care so you don’t \n have to.',
  ];
  const NextAnimation = keyframes`
	0% {left:100% }
	100% { left:0% }
`;
  const PreviousAnimation = keyframes`
	0% {left:-100% }
	100% { left:0% }
`;
  const Introduction = keyframes`
    0% {opacity:.1;top:20px }
    100% { opacity :1;top:0px }
`;
  let animation = introduction
    ? Introduction
    : direction === 1
    ? NextAnimation
    : PreviousAnimation;

  const copyQuantity = Copy.length;

  const Next = () => {
    if (introduction) {
      setIntroduction(false);
    }
    NextValueProp();
    setDirection(1);
    index < copyQuantity - 1 ? set(index + 1) : set(copyQuantity - 1);
  };
  const Previous = () => {
    if (introduction) {
      setIntroduction(false);
    }
    if (index > 0) {
      setDirection(-1);
      index > 0 ? set(index - 1) : set(0);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: Next,
    onSwipedRight: Previous,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <PageContainer {...handlers}>
      <TitleContainer>
        <Img src={logo} />
      </TitleContainer>

      <CopyWrapper key={keys[index]} animation={animation}>
        <CopyTitle>{Copy[index]} </CopyTitle>
        <CopySubTitle>{SubCopy[index]}</CopySubTitle>
      </CopyWrapper>

      <FlexContainer>
        <ArrowsContainer>
          <Icon src={arrowPrevious} onClick={Previous} />
          <Icon src={arrowNext} onClick={Next} />
        </ArrowsContainer>
      </FlexContainer>

      <BottomStaticContainer>
        <Button>
          <div
            onClick={() => {
              LandingGetStarted();
              history.push('/privacy-policy');
            }}
          >
            Get Started
          </div>
        </Button>
        <SignInContainer>
          Already a member?{' '}
          <StyledLink to='/dashboard' onClick={LandingLogin}>
            Sign In
          </StyledLink>
        </SignInContainer>
        <ProgressIndicator index={index} length={Object.keys(Copy).length} />
      </BottomStaticContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: static;
  width: 100vw;
  height: 100vh;
  background-color: #f4f1e9;
  overflow: hidden;
  @media (max-width: 768px) {
  }
`;

const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;

const TitleContainerAnimation = keyframes`
	0% {top: 5px;opacity:0 }
	20%{opacity:0}
	100% { top: 30px;opacity:1 }
`;
const TitleContainer = styled.div`
    position:absolute;
    top:30px;
    width:100vw;
    height:30px;
    display:flex;
    position absolute;
	  justify-content: center;
	  z-index:2;
	  animation-name: ${TitleContainerAnimation};
	  animation-duration: 1s;
`;

const Img = styled.img`
  height: 100%;
`;

const Icon = styled.img`
  height: 20px;
  margin: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowsContainer = styled.div`
  position: relative;
  bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

const CopyWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 2;
  will-change: transform, opacity;
  animation-name: ${(props) => props.animation};
  animation-duration: 1s;
`;

const CopyTitle = styled.div`
  position: relative;
  bottom: 50px;
  max-width: 576px;
  margin: 20px;
  text-align: center;
  font-size: 32px;
  font-family: Sans-Serif;
  line-height: 150%;
  white-space: pre-line;
  color: #294254;
`;
const CopySubTitle = styled.div`
  position: relative;
  bottom: 70px;
  max-width: 576px;
  height: 72px;
  margin: 36px;
  text-align: center;
  font-size: 16px;
  font-family: Sans-Serif;
  line-height: 153%;
  white-space: pre-line;
  color: #294254;
`;
const BottomStaticContainerAnimation = keyframes`
	0% {bottom: 10px;opacity:0 }
	20%{opacity:0}
	100% { bottom: 40px;opacity:1 }
`;
const BottomStaticContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100vw;
  height: 159px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  animation-name: ${BottomStaticContainerAnimation};
  animation-duration: 1s;
  @media (max-width: 768px) {
    bottom: 0px;
  }
`;
const SignInContainer = styled.div`
  width: 327px;
  height: 24px;
  font-family: Sans-Serif;
  font-size: 16px;
  line-height: 153%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #294254;
`;
const StyledLink = styled(Link)`
  color: #3b5b80;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #3b5b80;
`;

export default connect(null, {
  NextValueProp,
  LandingGetStarted,
  LandingLogin,
})(withTracker(Welcome, 'Landing Page View'));

import { EventTypes } from 'redux-segment';
import { segmentTypes } from '../constants';

export const createTrackEvent = (type, event) => {
  return {
    type,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event,
        },
      },
    },
  };
};

export const pageViewEvent = (name) => {
  return {
    type: segmentTypes.PAGE_VIEW_EVENT,
    meta: {
      analytics: {
        eventType: EventTypes.page,
        eventPayload: {
          name,
        },
      },
    },
  };
};

export const NextValueProp = () =>
  createTrackEvent('VALUE_PROP_EVENT', 'Next value props is viewed');
export const LandingGetStarted = () =>
  createTrackEvent('LANDING_GET_STARTED', 'Landing Page - Get Started');
export const LandingLogin = () =>
  createTrackEvent('LANDING_LOGIN', 'Landing Page - Login');

import React, { useState } from 'react';
import styled from 'styled-components';

const ScrollComponent = (props) => {
  const [selected, setSelected] = useState('');
  const { labels, width, onSelect } = props;
  const clickHandler = (i) => {
    i === selected ? setSelected('') : setSelected(i);
    onSelect(labels[i]);
  };
  return (
    <Container width={width}>
      {labels.map((el, i) => {
        return (
          <Block key={labels[i]}>
            <Element
              onClick={() => clickHandler(i)}
              highlighted={selected === i}
            >
              <div>{labels[i]}</div>
            </Element>
          </Block>
        );
      })}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.width || 'auto'};
  height: 100%;
  overflow: auto;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  @media (max-width: 768px) {
    flex-direction: row;

    justify-content: flex-start;
  }
`;

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 96px;
  height: 32px;
  border-radius: 4px;
  border: 3px solid #2f4660;
  background-color: ${(props) =>
    props.highlighted ? '#2F4660' : 'transparent'};
  color: ${(props) => (props.highlighted ? 'white' : 'black')};
  font-size: 12px;
  overflow: auto;
  cursor: pointer;
`;

const Block = styled.div`
  display: flex;
  width: 200px;
  height: 100%;
  flex-direction: column;
  flex: 0 0 56px;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  scroll-snap-align: start;
`;

export default ScrollComponent;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { useChain } from 'react-spring';
import { useHistory } from 'react-router-dom';

import Typography from '../atoms/Typography';
import AnimatedTitle from '../atoms/AnimatedTitle';
import AnimatedContent from '../atoms/AnimatedContent';
import { palette } from '../theme/palette';
import OshiHeader from './OshiHeader';
import OshiProgressBar from './OshiProgressBar';

function AnimatedRoute({
  title,
  children,
  progressPercentage,
  nextRoute,
  animationDirection,
  setAnimationDirection,
}) {
  const titleAnimeRef = useRef();
  const contentAnimeRef = useRef();
  const history = useHistory();
  const [resetAnimaions] = useState(false);
  const [
    cameAfterOshiHeaderBackButtonClick,
    setCameAfterOshiHeaderBackButtonClick,
  ] = useState(false);
  const cameAfterBrowserBackButtonClick = history.action === 'POP';

  function handleAnimationFinish(animation) {
    if (cameAfterOshiHeaderBackButtonClick) {
      history.goBack();
    } else if (animation.opacity === 0) {
      history.push(nextRoute);
    }
  }

  function handleBackArrow() {
    setCameAfterOshiHeaderBackButtonClick(true);
    setAnimationDirection && setAnimationDirection('unmount');
  }

  useChain([titleAnimeRef, contentAnimeRef], [0, 0.5]);

  return (
    <Container>
      <OshiHeader action={handleBackArrow} type='back' />
      <PaddedContainer>
        {progressPercentage >= 0 ? (
          <OshiProgressBar
            cameAfterOshiHeaderBackButtonClick={
              cameAfterOshiHeaderBackButtonClick
            }
            progressPercentage={progressPercentage}
          />
        ) : null}

        <AnimatedTitle
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          cameAfterOshiHeaderBackButtonClick={
            cameAfterOshiHeaderBackButtonClick
          }
          animationRef={titleAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          progressPercentage={progressPercentage}
        >
          <Typography
            styles={{ color: palette.darkPrimaryText }}
            type='paragraph'
          >
            {title}
          </Typography>
        </AnimatedTitle>

        <AnimatedContent
          style={{ flexDirection: 'column' }}
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          animationRef={contentAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          handleAnimationFinish={handleAnimationFinish}
        >
          {children}
        </AnimatedContent>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const PaddedContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  align-items: center;
  padding-top: 50px;
`;

export default AnimatedRoute;

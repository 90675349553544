import React from 'react';
import styled, { keyframes } from 'styled-components';

const ProgressIndicator = (props) => {
  const nodes = Array(props.length).fill(0);
  nodes.splice(props.index, 1, 1);

  return (
    <Container quantity={props.length}>
      {nodes.map((node) => {
        return node === 1 ? <ActiveNode /> : <Node />;
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: ${(props) => props.quantity * 20}px;
  width: 78px;
  height: 38px;
`;

const Node = styled.div`
  width: 6px;
  height: 6px;
  background: #3b5b80;
  border-radius: 100px;
`;
const ActiveNodeAnimation = keyframes`
	0% {width: 6px }
	100% { width: 26px }
`;

const ActiveNode = styled.div`
  width: 26px;
  height: 6px;
  background: #3b5b80;
  border-radius: 100px;
  animation-name: ${ActiveNodeAnimation};
  animation-duration: 0.2s;
`;

export default ProgressIndicator;

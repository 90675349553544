import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import '../assets/styles/calendar.css';
import Button from '../components/Button';
import { AppointmentContext } from '../utils/context';
import ScrollComponent from '../components/ScrollComponent';
import OshiHeader from '../components/OshiHeader';
import Title from '../components/Title';
import withTracker from '../wrappers/withTracker';

const formatDate = (date) => {
  return String(date).split(' ').splice(0, 4).join(' ');
};
const labels = [
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 AM',
  '12:30 AM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
];
const AppointmentCalendar = () => {
  const [selected, setSelected] = useState(new Date());
  const [appointmentDate, setAppointmentDate] = useState(
    formatDate(new Date())
  );
  const [appointmentTime, setAppointmentTime] = useState();
  const [, setAppointment] = useContext(AppointmentContext);
  const [ButtonDisableState, setButtonDisableState] = useState(true);
  const history = useHistory();

  const formatWeekDayNames = (locale) => {
    const str = String(locale).split('');
    const firstChar = str[0];
    return firstChar;
  };

  const onDateChange = (date) => {
    setAppointmentDate(formatDate(date));
    setSelected(date);
  };
  const onTimeSelect = (time) => {
    setAppointmentTime(time);
    if (ButtonDisableState) {
      setButtonDisableState(false);
    }
  };

  const onBook = () => {
    setAppointment({
      Appointment: true,
      AppointmentTime: appointmentTime,
      AppointmentDate: appointmentDate,
    });
    history.push('/first-appointment-overview');
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <OshiHeader
          type='back'
          action={() => history.push('/first-appointment-description')}
        />
      </HeaderContainer>
      <TitleContainer>
        <Title />
      </TitleContainer>

      <FlexContainer>
        <CopyWrapper>
          <CopyTitle>When would be most convenient for you?</CopyTitle>
          <CopySubTitle>
            Choose a date to see available appointment times
          </CopySubTitle>
        </CopyWrapper>

        <ContentContainer>
          <CalendarContainer>
            <Calendar
              onChange={onDateChange}
              value={selected}
              next2Label={false}
              prev2Label={false}
              calendarType='US'
              formatShortWeekday={(locale, date) =>
                formatWeekDayNames(date, 'dd')
              }
              maxDetail='month'
              view='month'
              showNeighboringMonth={false}
            />
          </CalendarContainer>
          <Border />
          <TimeWrapper>
            <TimeTitle>Times Available</TimeTitle>
            <TimesContainer>
              <ScrollContainer>
                <ScrollComponent
                  labels={labels}
                  width='365px'
                  onSelect={onTimeSelect}
                />
              </ScrollContainer>
            </TimesContainer>
          </TimeWrapper>
        </ContentContainer>

        <BottomStaticContainer>
          <Button onClick={onBook} disabled={ButtonDisableState}>
            Book Appointment
          </Button>
        </BottomStaticContainer>
      </FlexContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fbf8f7;
  @media (max-width: 768px) {
    height: 100%;
  }
`;
const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 2;
`;
const TitleContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const CopyWrapper = styled.div`
  position: absolute;
  top: 91px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    top: 58px;
    height: 60px;
  }
`;
const CopyTitle = styled.div`
  max-width: 576px;
  text-align: center;
  font-size: 14px;
  font-family: Sans-Serif;
  line-height: 168%;
  letter-spacing: 0.04em;
  white-space: pre-line;
  margin-bottom: 8px;
  color: #2f4660;
`;
const CopySubTitle = styled.div`
  max-width: 576px;
  text-align: center;
  font-size: 12px;
  font-family: Sans-Serif;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  white-space: pre-line;
  color: #818d9b;
`;
const ContentContainer = styled.div`
  position: absolute;
  top: 225px;
  width: 700px;
  height: 340px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  @media (max-width: 768px) {
    top: 125px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 415px;
  }
`;
const CalendarContainer = styled.div`
  width: 343px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    position: relative;
    height: 330px;
  }
`;
const Border = styled.div`
  position: relative;
  left: 50px;
  top: 20px;
  height: 300px;
  border: 0.5px solid lightgray;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TimeWrapper = styled.div`
  display: flex;
  height: 350px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    margin: 30px 0px 0px 0px;
    height: 100px;
    border: none;
    justify-content: space-around;
  }
`;
const TimeTitle = styled.div`
	max-width: 576px;
	text-align: center;
	font-size: 14px;
	font-family: Sans-Serif;
	font - weight: 600;
  line-height: 168%;
  letter-spacing: 0.04em;
  white-space: pre-line;
  margin-bottom:8px;
	color: #2F4660;
	@media (max-width: 768px) {
	}
`;
const TimesContainer = styled.div`
  max-width: 576px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-family: Sans-Serif;
  line-height: 131.7%;
  letter-spacing: 0.06em;
  white-space: pre-line;
  color: #afb6be;
  @media (max-width: 768px) {
    overflow: hidden;
  }
`;

const BottomStaticContainer = styled.div`
  position: absolute;
  top: 610px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    top: 570px;
  }
`;

const ScrollContainer = styled.div`
  width: 95%;
`;

export default connect(
  null,
  {}
)(withTracker(AppointmentCalendar, 'Appointment Book Page View'));

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';

import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiHeader from '../components/OshiHeader';

function BookAnAppointment() {
  const [animationStep, setAnimationStep] = useState('mount');
  const history = useHistory();

  const firstNodeAnime = useSpring({
    width: animationStep !== 'end' ? '44px' : '12px',
    background:
      animationStep !== 'end' ? palette.mediumTurqoise : palette.melon,
  });

  const secondNodeAnime = useSpring({
    width: animationStep === 'end' ? '44px' : '12px',
    background:
      animationStep === 'end' ? palette.mediumTurqoise : palette.melon,
  });

  function handleFadeFinish(style) {
    if (style.opacity === 1) {
      setAnimationStep('end');
      setTimeout(() => {
        history.push('/first-appointment-book');
      }, 2000);
    }
  }

  const fade = useSpring({
    // reverse: animationStep === 'hide',
    onRest: handleFadeFinish,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  useEffect(() => {
    setTimeout(() => {
      setAnimationStep('hide');
    }, 1800);
  }, []);

  function goBack() {
    history.goBack();
  }

  let title = 'Book an appointment';
  let subCopy =
    'Great, now let’s find a convenient appointment to meet with a coach.';

  if (animationStep === 'end') {
    title = 'Book an appointment';
    subCopy =
      'Great, now let’s find a convenient appointment to meet with a coach.';
  }

  return (
    <Container>
      <OshiHeader type='back' action={goBack} />
      <PaddedContainer>
        <TitleContainer style={fade}>
          <Typography styles={{ color: palette.darkPrimaryText }} type='h1'>
            {title}
          </Typography>
        </TitleContainer>

        <ProgressContainer>
          <ProgressNode />
          <ProgressNode style={firstNodeAnime} />
          <ProgressNode style={secondNodeAnime} />
        </ProgressContainer>

        <SubCopyContainer style={fade}>
          <Typography styles={{ color: palette.darkPrimaryText }} type='button'>
            {subCopy}
          </Typography>
        </SubCopyContainer>
      </PaddedContainer>
    </Container>
  );
}

const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
`;

const PaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0 15px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  align-items: center;
`;

const TitleContainer = styled(animated.div)`
  flex: 1;
  padding-top: 142px;
  padding-bottom: 54px;
`;

const ProgressContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ProgressNode = styled(animated.div)`
  width: 12px;
  height: 12px;
  background: ${palette.melon};
  border-radius: 90px;
  margin: 5px;
`;

const SubCopyContainer = styled(animated.div)`
  flex: 1;
  text-align: center;
  padding-top: 49px;
`;

export default BookAnAppointment;

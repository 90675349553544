import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';
import GooglePlacesModal from '../components/GooglePlacesModal';

function StreetAddress() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  const [address, setAddress] = useState('');
  const [isModalActive, setIsModalActive] = useState(false);
  const [googleMapsValue, setGoogleMapsValue] = useState(null);
  const addressInputRef = useRef();

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  function handleChange(e) {
    const { value } = e.target;

    setAddress(value);

    if (value.length >= 2) {
      setIsModalActive(true);
    }
  }

  function handleModalClose() {
    setIsModalActive(false);
    setAddress('');
    addressInputRef.current.focus();
  }

  return (
    <>
      <AnimatedRoute
        nextRoute='/address'
        progressPercentage={75}
        title='What’s your mailing address?'
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
      >
        <Form>
          <OshiInput
            forwardRef={addressInputRef}
            id='streetAddress'
            autoFocus
            type='text'
            label='Street Address'
            autoComplete='off'
            onChange={handleChange}
            value={address}
          />
        </Form>

        <NextButtonContainer>
          <Button disabled={true} onClick={handleNext}>
            Continue
          </Button>
        </NextButtonContainer>
      </AnimatedRoute>

      {isModalActive && (
        <GooglePlacesModal
          initialInput={address}
          setAddress={setAddress}
          handleModalClose={handleModalClose}
          googleMapsValue={googleMapsValue}
          setGoogleMapsValue={setGoogleMapsValue}
        />
      )}
    </>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

export default StreetAddress;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

import getAge from '../utils/getAge';
import InputErrorMessage from '../atoms/InputErrorMessage';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';

function Birthdate() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  function dobValidate({ birthday }) {
    const errors = {};
    const splitBirthday = birthday.split(' / ').join('/').split('/');
    const month = Number(splitBirthday[0]);
    const day = Number(splitBirthday[1]);

    if (birthday.length < 14) {
      errors.length = true;
      return errors;
    }

    if (month < 1 || month > 12) {
      errors.month = true;
      return errors;
    }

    if (day < 1 || day > 31) {
      errors.day = true;
      return errors;
    }

    const age = getAge(birthday);
    if (age < 18 || age > 140) {
      errors.age = true;
      return errors;
    }

    return errors;
  }

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      birthday: '',
    },
    validate: dobValidate,
    onSubmit: () => handleNext(),
  });

  const isValid = formik.dirty && Object.keys(formik.errors).length === 0;
  return (
    <AnimatedRoute
      nextRoute='/phone-number'
      progressPercentage={25}
      title='When’s your birthday?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='birthday'
          autoFocus
          type='text'
          label='Birthday'
          autoComplete='off'
          isValid={isValid}
          maskPlaceholder=''
          onChange={formik.handleChange}
          value={formik.values.birthday}
          mask='99 / 99 / 9999'
        />

        <ErrorsContainer>
          <InputErrorMessage isValid={isValid} error='mm / dd / yyyy' />
        </ErrorsContainer>

        <AgeMessageContainer>
          <Typography
            styles={{ color: palette.darkSecondaryText }}
            type='subcopy'
          >
            You must be at least 18 years of age to be eligible for Oshi
            Health's services.
          </Typography>
        </AgeMessageContainer>
      </Form>

      <NextButtonContainer>
        <Button
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgeMessageContainer = styled.div`
  display: flex;
  text-align: center;
  margin-top: 36px;
  justify-content: center;
  @media only screen and (min-width: 421px) {
    padding: 0 22%;
  }
`;

export default Birthdate;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import OshiInput from '../components/OshiInput';
import AnimatedRoute from '../components/AnimatedRoute';
import Button from '../components/Button';

import InputErrorMessage from '../atoms/InputErrorMessage';

import regex from '../utils/regex';

function PhoneNumber() {
  const [animationDirection, setAnimationDirection] = useState('mount'); // mount, back, forward
  function phoneNumberValidation({ phoneNumber }) {
    // masked phone coming in 'xxx - xxx - xxxx'
    // regex can only match 'xxx-xxx-xxxx'
    // so we have to remove the spaces
    const splitPhoneNum = phoneNumber.split(' - ').join('-');
    const errors = {};

    if (!splitPhoneNum.match(regex.phone)) {
      errors.format = true;
      return errors;
    }

    return errors;
  }

  function handleNext() {
    // store info in redux;
    // animate and transition
    setAnimationDirection('unmount');
  }

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validate: phoneNumberValidation,
    onSubmit: () => handleNext(),
  });

  return (
    <AnimatedRoute
      nextRoute='/street-address'
      progressPercentage={50}
      title='What’s the best number to contact you at?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
    >
      <Form>
        <OshiInput
          id='phoneNumber'
          autoFocus
          type='text'
          label='Phone'
          autoComplete='off'
          isValid={formik.dirty && Object.keys(formik.errors).length === 0}
          maskPlaceholder='    -     -     '
          onChange={formik.handleChange}
          value={formik.values.birthday}
          mask='999 - 999 - 9999'
        />

        <ErrorsContainer>
          <InputErrorMessage
            isValid={formik.dirty && Object.keys(formik.errors).length === 0}
            error='XXX-XXX-XXXX'
          />
        </ErrorsContainer>
      </Form>

      <NextButtonContainer>
        <Button
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
          onClick={formik.submitForm}
        >
          Continue
        </Button>
      </NextButtonContainer>
    </AnimatedRoute>
  );
}

const Form = styled.form`
  height: 190px;
`;

const NextButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  flex: 1;
  justify-content: center;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default PhoneNumber;
